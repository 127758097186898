import * as React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../../../components/Footer/Footer'
import Bounce from 'react-reveal/Bounce'
import Gallery, { useGallery } from '../../../components/Gallery/Gallery'
import images from '../../../components/Gallery/ImageLists/tusk-2019/tusk-2019-images'

import './tusk-2019.scss'

const Tusk2019 = () => {
  const { imageSelected, isOpen, onImageClicked, setIsOpen } = useGallery()

  return (
    <>
      <div id="tusk-2019">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          <title>Emso</title>
          <meta name="description" content="Emso thank you page for Tusk 2019 Safaricom Marathon" />
        </Helmet>

        <section id="hero" className="hero fullscreen wrap">
          <nav className="wrap nav">
            <div className="container cf">
              <a href="https://www.emso.com/" title="Back to Emso" id="logo">
                <img src="/images/tusk-2019/images/emso-logo.svg" alt="Emso logo" width="90" height="62" />
              </a>
            </div>
          </nav>
          <div className="statement">
            <div className="container cf">
              <div className="row">
                <div className="col-base-10 col-xs-9 col-sm-7 col-lg-8">
                  <h1 className="heading-homepage">Asante!*</h1>
                  <p className="text-secondary">*Asante is 'thank you' in the Swahili language</p>
                </div>
              </div>
            </div>
          </div>
          <div className="video-link-container">
            <div className="cf">
              <a href="#safaricom-video" id="go-to-video" className="go-to-video" title="Go to video">
                <span className="video-link">
                  <h6 className="yellow">Watch the video of our trip to Kenya</h6>
                </span>
                <img
                  alt="arrow button down"
                  className="arrow-btn-down"
                  src="/images/tusk-2019/images/down-arrow-yellow.svg"
                  width="32"
                  height="32"
                />
              </a>
            </div>
          </div>
          <div className="img-container-cover">
            <video id="video" autoPlay muted loop playsInline>
              <source id="videoSource" src="/images/tusk-2019/videos/lion.mov" type="video/mp4" />
            </video>
            <div className="overlay" />
          </div>
        </section>

        <main className="wrap" role="main">
          <section id="tusk-box" className="section-padding">
            <div className="container">
              <div className="row">
                <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                  <p className="text-secondary">*Asante is 'thank you' in the Swahili language</p>
                  <div className="content">
                    <img src="/images/tusk-2019/images/tusk-logo.svg" alt="Tusk logo" className="tusk-logo" width="219" height="96" />
                    <p>
                      For many years, Emso has been a supporter of Tusk, a dynamic and adaptable organization that is advancing conservation
                      across Africa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="number-box" className="section-padding">
            <div className="right-column">
              <div className="heading-wrapper">
                <div className="title h1 yellow">12,000+</div>
                <div className="icon-container">
                  <img src="/images/tusk-2019/images/icon-education-blue.svg" className="icon" />
                </div>
              </div>
              <p>Over 12,000 students have been supported through the Lewa conservancy</p>
            </div>
          </section>

          <section id="text-and-quiz" className="section-padding">
            <div className="container">
              <div className="row">
                <div className="col-base-12 col-xs-12 col-lg-7 col-md-7 col-sm-7">
                  <p>
                    With your help and generosity, Emso has been awarded the Corporate Fundraising Award for the 2nd year in a row,
                    following its fundraising efforts in support of Tusk at the Safaricom Marathon, which is held annually on the Lewa
                    Conservancy in Kenya.{' '}
                  </p>
                  <p>
                    The Emso Team raised USD <strong>115,000</strong> through individual fundraising and from its third annual Quiz Night in
                    London – and, excitingly, its very first Trivia Night in New York City.
                  </p>
                  <p>
                    Since Emso first participated in the Marathon in 2014, the Team has raised approximately USD <strong>400,000</strong>{' '}
                    for Tusk and its projects.
                  </p>
                  <p>
                    The proceeds from the Marathon fundraising will be used by Tusk and the Lewa Conservancy to support the surrounding
                    community through a variety of projects - including healthcare, education, community, and wildlife conservation
                    initiatives.
                  </p>
                  <p>
                    Each year while visiting Lewa for the Marathon, the team has the opportunity to visit different projects that receive
                    funding directly from the Marathon proceeds, and it has been truly phenomenal to see the difference that our
                    contributions have made to Lewa’s surrounding communities over the years: schools have been built and improved, water
                    treatment facilities have been engineered and maintained, and ground-breaking anti-poaching methods have been utilized
                    to protect some of Lewa’s most threatened species including white and black rhinos, elephants, lions, and Grevy zebras.
                  </p>
                  <h2> Thank you again!</h2>
                  <p>
                    We cannot thank you enough for attending our Quiz and Trivia Nights, for donating to our personal fundraisers, and
                    finally, for wishing us luck as we tackled the tough Safaricom marathon course. We hope that the video below gives you a
                    glimpse into our visit to Lewa, and most importantly, of the children, communities, and animals that are directly
                    benefiting from your support.
                  </p>
                  <p>You can see photos from our Quiz Night in London and Trivia Night in New York via the links on our dedicated pages.</p>
                </div>
              </div>
            </div>

            <div className="quiz-box right-column hide-xs">
              <Bounce bottom>
                <div className="row">
                  <div className="cols col-base-12 col-sm-12 col-md-6">
                    <span className="cta">
                      <a href="/tusk/2019/trivia-ny" title="See all quiz photos" target="_blank" className="btn">
                        Emso Trivia Night NYC
                        <img src="/images/tusk-2019/images/open-in-new-tab.svg" />
                      </a>
                    </span>
                    <div className="img-container">
                      <img src="/images/tusk-2019/images/quiznight/nyc/preview/Thumbnail%201.jpg" />
                    </div>
                    <div className="img-container mt-20 hide-sm">
                      <img src="/images/tusk-2019/images/quiznight/nyc/preview/thumbnail%202.jpg" />
                    </div>
                    <div className="img-container mt-20 hide-sm">
                      <img src="/images/tusk-2019/images/quiznight/nyc/preview/thumbnail%203.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-12 col-md-6">
                    <span className="cta">
                      <a href="/tusk/2019/quiz-london" title="See all quiz photos" target="_blank" className="btn">
                        Emso Quiz Night London
                        <img src="/images/tusk-2019/images/open-in-new-tab.svg" />
                      </a>
                    </span>
                    <div className="img-container">
                      <img src="/images/tusk-2019/images/quiznight/london/preview/thumnail%201.jpg" />
                    </div>
                    <div className="img-container mt-20 hide-sm">
                      <img src="/images/tusk-2019/images/quiznight/london/preview/Thumbnail%202.jpg" />
                    </div>
                    <div className="img-container mt-20 hide-sm">
                      <img src="/images/tusk-2019/images/quiznight/london/preview/thumbnail%203.jpg" />
                    </div>
                  </div>
                </div>
              </Bounce>
            </div>
          </section>

          <section id="snapshot" className="section-padding">
            <div className="container">
              <div className="animal-images">
                <img className="elephant" src="/images/tusk-2019/images/snapshot/elephant.svg" />
                <img className="lion" src="/images/tusk-2019/images/snapshot/lion.svg" />
                <img className="giraffe" src="/images/tusk-2019/images/snapshot/giraffe.svg" />
                <img className="rhino" src="/images/tusk-2019/images/snapshot/rhino.svg" />
              </div>
            </div>
            <div className="wrapper">
              <div className="background">
                <div className="gradient">
                  <div className="container">
                    <div className="col-xs-12 col-sm-12 col-lg-8">
                      <h2 className="headline yellow"> Here’s just a snapshot of how you’ve helped: </h2>
                      <ul>
                        <li>
                          <img src="/images/tusk-2019/images/snapshot/student.svg" />
                          <p>
                            <strong>Education</strong>: Over 12,000 students have been supported through the Lewa conservancy
                          </p>
                        </li>
                        <li>
                          <img src="/images/tusk-2019/images/snapshot/heart.svg" />
                          <p>
                            <strong>Conservancy</strong>: More than 50,000 people can now address 90% of their healthcare needs through Lewa
                            healthcare facilities
                          </p>
                        </li>
                        <li>
                          <img src="/images/tusk-2019/images/snapshot/rhino-head.svg" />
                          <p>
                            <strong>Conservation</strong>: There has been zero poaching of rhino on the Lewa-Borana landscape for over 5
                            years
                          </p>
                        </li>
                        <li>
                          <img src="/images/tusk-2019/images/snapshot/drop.svg" />
                          <p>
                            <strong>Community</strong>: More than 2,000 people now have access to clean and safe water thanks to community
                            programs
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="quiz-box-mobile">
            <Bounce bottom>
              <div className="quiz-box">
                <span className="cta">
                  <a href="trivia-ny.html" title="See all quiz photos" target="_blank" className="btn">
                    Emso Trivia Night NYC
                    <img src="/images/tusk-2019/images/open-in-new-tab.svg" />
                  </a>
                </span>
                <div className="row">
                  <div className="cols col-base-12 col-sm-6">
                    <div className="img-container">
                      <img src="/images/tusk-2019/images/quiznight/nyc/preview/Thumbnail%201.jpg" />
                    </div>
                  </div>
                </div>
                <span className="cta">
                  <a href="quiz-london.html" title="See all quiz photos" target="_blank" className="btn">
                    Emso Quiz Night London
                    <img src="/images/tusk-2019/images/open-in-new-tab.svg" />
                  </a>
                </span>
                <div className="row">
                  <div className="cols col-base-12 col-sm-6">
                    <div className="img-container">
                      <img src="/images/tusk-2019/images/quiznight/london/preview/thumnail%201.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </Bounce>
          </section>

          <section id="safaricom-video" className="section-padding">
            <div className="container">
              <div className="row">
                <div className="col-base-12 col-xs-12 col-sm-12 col-lg-8">
                  <h1>Our 2019 visit to Lewa</h1>
                  <div className="video">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/KclfVmFGKOk?rel=0&amp;showinfo=0"
                      frameBorder="0"
                      allow="encrypted-media"
                      allowFullScreen
                    />
                  </div>
                  <blockquote>
                    One of the top ten races to run in your life.<span className="author">— Runner’s World</span>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>

          <section id="safaricom-galery" className="section-padding">
            <Gallery images={images} imageSelected={imageSelected} isOpen={isOpen} setIsOpen={setIsOpen} />

            <Bounce bottom>
              <div className="container">
                <div className="row">
                  <div className="col-base-12 col-xs-9 col-sm-7 col-lg-8">
                    <h6>Photo gallery</h6>
                  </div>
                </div>
                <div className="row photos">
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/c5ea4b3d-0b81-493b-89ce-1b2012fe1b72.JPG')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/c5ea4b3d-0b81-493b-89ce-1b2012fe1b72.JPG" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/whiteboard.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/whiteboard.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/lewa%20clinic.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/lewa%20clinic.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/Lewa-20190628-0369.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/Lewa-20190628-0369.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/Lewa-20190628-0423.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/Lewa-20190628-0423.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/Lewa-20190628-0685.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/Lewa-20190628-0685.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/Lewa-20190628-0839.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/Lewa-20190628-0839.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/Lewa-20190629-0936.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/Lewa-20190629-0936.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/Lewa-20190630-1231.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/Lewa-20190630-1231.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/PHOTO-2019-06-29-13-45-08.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/PHOTO-2019-06-29-13-45-08.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/Team%20-%20breakfast.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/Team%20-%20breakfast.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/team%20with%20elephants%205.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/team%20with%20elephants%205.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/team%202.jpg')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/team%202.jpg" />
                    </div>
                  </div>
                  <div className="cols col-base-12 col-sm-6">
                    <div
                      className="img-container"
                      onClick={() => {
                        onImageClicked('/images/tusk-2019/images/homepage/large/d5acf557-9ed2-4452-802c-3d72f4453359.JPG')
                      }}
                    >
                      <img src="/images/tusk-2019/images/homepage/small/d5acf557-9ed2-4452-802c-3d72f4453359.JPG" />
                    </div>
                  </div>
                </div>
              </div>
            </Bounce>
          </section>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default Tusk2019
