const tusk2019Images = [
    '/images/tusk-2019/images/homepage/large/c5ea4b3d-0b81-493b-89ce-1b2012fe1b72.JPG',
    '/images/tusk-2019/images/homepage/large/whiteboard.jpg',
    '/images/tusk-2019/images/homepage/large/lewa%20clinic.jpg',
    '/images/tusk-2019/images/homepage/large/Lewa-20190628-0369.jpg',
    '/images/tusk-2019/images/homepage/large/Lewa-20190628-0423.jpg',
    '/images/tusk-2019/images/homepage/large/Lewa-20190628-0685.jpg',
    '/images/tusk-2019/images/homepage/large/Lewa-20190628-0839.jpg',
    '/images/tusk-2019/images/homepage/large/Lewa-20190629-0936.jpg',
    '/images/tusk-2019/images/homepage/large/Lewa-20190630-1231.jpg',
    '/images/tusk-2019/images/homepage/large/PHOTO-2019-06-29-13-45-08.jpg',
    '/images/tusk-2019/images/homepage/large/Team%20-%20breakfast.jpg',
    '/images/tusk-2019/images/homepage/large/team%20with%20elephants%205.jpg',
    '/images/tusk-2019/images/homepage/large/team%202.jpg',
    '/images/tusk-2019/images/homepage/large/d5acf557-9ed2-4452-802c-3d72f4453359.JPG'
]

export default tusk2019Images;